import BenefitsSection from './components/BenefitsSection';
import FeaturesSection from './components/FeaturesSection';
import FooterSection from './components/FooterSection';
import HeroSection from './components/HeroSection';
import HowToUseSection from './components/HowToUseSection';
import PriceSection from './components/PriceSection';
import UseCasesSection from './components/UseCasesSection';

const LandingPage = () => {
  return (
    <div className="space-y-12">
      <HeroSection />
      <BenefitsSection />
      <UseCasesSection />
      <HowToUseSection />
      <FeaturesSection />
      <PriceSection />
      <FooterSection />
    </div>
  );
};

export default LandingPage;
