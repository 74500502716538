import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { UserVM } from '../viewModels';
import { UpdateUserFunc } from '../firebaseFunctions';
import { userVMToDb } from './mapping';

type UpdateUserSettingsData = Partial<UserVM> & Required<Pick<UserVM, 'id'>>;

export default function useUpdateUserSettings() {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, Error, UpdateUserSettingsData, unknown>({
    mutationFn: async (data: UpdateUserSettingsData) => {
      const updateRequest = {
        ...userVMToDb(data),
        id: data.id!,
      };

      await UpdateUserFunc(updateRequest);
    },

    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: [queryKeys.users],
      }),
  });

  return mutation;
}
