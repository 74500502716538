import React from 'react';
import AttachGoogleAccountButton from './AttachGoogleAccountButton';

type GoogleCalendarSettingsViewEditProps = {
  userId: string;
};

const SettingsGoogleViewEdit: React.FC<
  GoogleCalendarSettingsViewEditProps
> = (props) => {
  return (
    <AttachGoogleAccountButton />
  );
};

export default SettingsGoogleViewEdit;
