import plural from 'plural-ru';

export function pluralDays(value: number) {
  return `${value} ${plural(value, 'день', 'дня', 'дней')}`;
}

export function pluralBookings(value: number) {
  return `${value} ${plural(value, 'запись', 'записи', 'записей')}`;
}

export function pluralCredits(value: number) {
  return `${value} ₽`;
}

export function pluralTime(minutes: number) {
  const days = Math.floor(minutes / 1440);
  const hoursLeft = Math.floor((minutes - days * 1440) / 60);
  const minutesLeft = minutes - days * 1440 - hoursLeft * 60;

  let result = days > 0 ? days + ' ' + plural(days, 'день', 'дня', 'дней') : '';
  result += hoursLeft > 0 ? (result ? ' ' : '') + hoursLeft + ' ' + plural(hoursLeft, 'час', 'часа', 'часов') : '';
  result += minutesLeft > 0 ? (result ? ' ' : '') + minutesLeft + ' ' + plural(minutesLeft, 'минута', 'минуты', 'минут') : '';
  return result;
}


export function enabled(value: boolean) {
  return value ? 'Включено' : 'Выключено'
}
