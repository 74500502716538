import React, { useState } from 'react';
import {
  Box,
  Fade,
  IconButton,
  List,
  ListItem,
  Paper,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ViewDetailsSection from './ViewControl';

import { SwitchTransition } from 'react-transition-group';
import { EditorDialog } from './EditorDialog';

const SwitchTransitionTimeout = 250;

export interface ViewFieldButton {
  icon: React.ReactElement;
  onClick: () => void;
}

export interface ViewFieldModal {
  title: string;
  content: React.ReactNode;
  saveButton: string;
}

export interface ViewField {
  icon: React.ReactElement;
  value: React.ReactNode;
  label: string;
  button?: ViewFieldButton;
  onClick?: () => void;
  modal?: React.ReactNode;
}

export interface ItemDetailsSectionProps {
  title: string;
  editor?: React.ReactNode;
  viewFields?: ViewField[];
  view?: React.ReactNode;
  editMode: 'inline' | 'modal';
  viewEditIcon?: React.ReactNode;
  onSave: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  onCancel: () => void;
  editorIsValid?: boolean;
}

const ViewEditControl: React.FC<ItemDetailsSectionProps> = (props) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    await props.onSave(event);
    if (props.editorIsValid) {
      setIsEditing(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    props.onCancel();
  };

  const editIcon = props.viewEditIcon ? props.viewEditIcon : <EditIcon />;

  return (
    <form onSubmit={handleSave}>
      <div className='pl-1 pb-1 text-panelHeader font font-panelHeader'>{props.title.toUpperCase()}</div>
      <Paper variant="outlined">
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box
            sx={{
              py: 1,
              px: 2,
              width: 1,
              overflow: 'hidden',
              whiteSpace: 'break-spaces',
            }}
          >
            <SwitchTransition mode="out-in">
              <Fade
                key={isEditing && props.editMode === 'inline' ? 'edit' : 'view'}
                timeout={SwitchTransitionTimeout}
                unmountOnExit
                mountOnEnter
              >
                {isEditing && props.editMode === 'inline' ? (
                  <Box className="py-2">{props.editor}</Box>
                ) : (
                  <Box>
                    {props.view ? props.view : null}
                    {props.viewFields ? (
                      <ViewDetailsSection fields={props.viewFields} />
                    ) : null}
                  </Box>
                )}
              </Fade>
            </SwitchTransition>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
              p: 1,
            }}
          >
            <SwitchTransition mode="out-in">
              <Fade
                key={isEditing && props.editMode === 'inline' ? 'edit' : 'view'}
                timeout={SwitchTransitionTimeout}
                unmountOnExit
                mountOnEnter
              >
                {isEditing && props.editMode === 'inline' ? (
                  <List sx={{ p: 0 }}>
                    <ListItem sx={{ p: 0, mb: 1.5 }}>
                      <IconButton sx={{ p: 0.5 }} onClick={handleCancel}>
                        <CloseIcon />
                      </IconButton>
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                      <IconButton
                        sx={{
                          p: 0.5,
                          backgroundColor: 'primary.main',
                          color: 'primary.contrastText',
                        }}
                        type="submit"
                      >
                        <DoneIcon />
                      </IconButton>
                    </ListItem>
                  </List>
                ) : (
                  <List sx={{ p: 0 }}>
                    {props.editor && (
                      <ListItem sx={{ p: 0 }}>
                        <IconButton
                          sx={{ p: 0.5, fontSize: 30, color: 'primary.main' }}
                          onClick={handleEdit}
                        >
                          {editIcon}
                        </IconButton>
                      </ListItem>
                    )}
                  </List>
                )}
              </Fade>
            </SwitchTransition>
          </Box>
        </Box>
      </Paper>
      <EditorDialog
        editor={props.editor}
        open={isEditing && props.editMode === 'modal'}
        onClose={() => setIsEditing(false)}
      />
    </form>
  );
};

export default ViewEditControl;
