import React from 'react';
import { Button } from '@mui/material';
import { useGoogleClient } from './GoogleClientProvider';
import useSetGoogleIntegration from '../queryHelpers/useSetGoogleIntegration';
import useAccount from '../queryHelpers/useAccount';
import { Google } from '@mui/icons-material';
import { useUserContext } from '../contexts/UserContext';

interface AttachGoogleAccountButtonProps {
  // Removed account prop
}

const AttachGoogleAccountButton: React.FC<
  AttachGoogleAccountButtonProps
> = () => {
  const googleClient = useGoogleClient();
  const useSetGoogleIntegrationMutation = useSetGoogleIntegration();
  const {user} = useUserContext();
  const account = useAccount(user?.accountId);

  const handleAttachCalendar = async () => {
    try {
      const offlineAccessResponse =
        await googleClient?.auth?.grantOfflineAccess();
      if (!offlineAccessResponse) {
        throw new Error('Could not get offline access');
      }

      await useSetGoogleIntegrationMutation.mutateAsync({
        accountId: user?.accountId!,
        code: offlineAccessResponse.code,
      });
    } catch (error) {
      console.error('Failed to attach Google Calendar', error);
    }
  };

  return (
    <>
      {!account.data?.googleAccount && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<Google />}
          onClick={handleAttachCalendar}
        >
          Подключить Google календарь
        </Button>
      )}
    </>
  );
};

export default AttachGoogleAccountButton;
