import React, { useMemo } from 'react';
import { Autocomplete, TextField, ListItem, ListItemText } from '@mui/material';
import tzData from 'tzdata';
import spacetime from 'spacetime';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

interface TimezoneSelectorProps {
  value: string;
  onChange: (value: string) => void;
  error: boolean;
}

export const getTimezoneDisplayText = (
  timezone: string,
): { offset: string; name: string; now: Date; offsetHours: number } | null => {
  let spacetime1 = null;
  try {
    spacetime1 = spacetime.now(timezone);
  } catch (e) {
    return null;
  }

  const offset = spacetime1.timezone().current.offset;
  const hours = Math.floor(Math.abs(offset)).toString().padStart(2, '0'); // Get absolute hours
  const minutes = ((Math.abs(offset) % 1) * 60).toString().padStart(2, '0'); // Get minutes
  const utcOffset =
    offset >= 0 ? `UTC+${hours}:${minutes}` : `UTC-${hours}:${minutes}`; // Format offset

  const now = new Date(
    spacetime1.year(),
    spacetime1.month(),
    spacetime1.date(),
    spacetime1.hour(),
    spacetime1.minute(),
  );
  return { offset: utcOffset, offsetHours: offset, name: timezone, now: now };
};

const getTimezones = () => {
  const timezones = Object.keys(tzData.zones);
  const formattedTimezones = timezones
    .map((timezone) => {
      const label = getTimezoneDisplayText(timezone);
      if (!label) {
        return null;
      }
      return { timezone, label }; // Return formatted data
    })
    .filter((tz) => tz !== null)
    .map((tz) => tz!);
  formattedTimezones.sort((a, b) => {
    return a.label.offsetHours - b.label.offsetHours; // Use subtraction for numeric comparison
  });

  return formattedTimezones; // Return the array of formatted timezones
};

const TimezoneSelector: React.FC<TimezoneSelectorProps> = ({
  value,
  onChange,
  error,
}) => {
  const { t } = useTranslation();
  const timezones = useMemo(() => getTimezones(), []);

  return (
    <Autocomplete
      options={timezones} // Use the entire formatted timezones array
      getOptionLabel={(option) =>
        `(${option.label.offset}) ${t(option.label.name)}`
      }
      value={timezones.find((tz) => tz.timezone === value) || null} // Match value with formatted timezone
      renderInput={(params) => (
        <TextField {...params} size="small" error={error} fullWidth />
      )}
      onChange={(_, newValue) => onChange(newValue ? newValue.timezone : '')} // Use utcOffset for onChange
      renderOption={(
        props,
        option, // Custom render for options
      ) => (
        <ListItem {...props} key={option.timezone}>
          <ListItemText
            primary={`(${option.label.offset}) ${t(option.label.name)}`}
            secondary={`Время сейчас: ${format(option.label.now, 'HH:mm')}`}
          />
        </ListItem>
      )}
    />
  );
};

export default TimezoneSelector;
