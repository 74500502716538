import { AppBar, Toolbar, Button, Tabs, Tab, Typography } from '@mui/material';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { routes } from '../../routes';

const LandingLayout: React.FC = () => {
  const location = useLocation();

  // Use URLSearchParams to parse the query parameters
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get('mode'); // Replace 'example' with your parameter name
  const isTestMode = mode === 'test';

  return (
    <>
      <AppBar position="fixed" variant="elevation" elevation={1}>
        <Toolbar variant="dense" className="bg-white text-black">
          <div className="flex-grow">
            <Tabs value={routes.landing} aria-label="basic tabs example">
              <Tab
                href={routes.landing}
                label="Главная"
                value={routes.landing}
              />
            </Tabs>
          </div>

          <div className='flex flex-row items-center space-x-2'>
            {!isTestMode && <Typography className='italic text-gray-400'>Скоро...</Typography>}
            <Button
              component={Link}
              variant="contained"
              to={isTestMode ? routes.login : routes.landing}
              color="primary"
              disabled={!isTestMode}
            >
              Войти
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <div className="pt-2 mt-12 px-4">
        <Outlet />
      </div>
    </>
  );
};

export default LandingLayout;
