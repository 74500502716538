import { Typography } from '@mui/material';

const FooterSection = () => {
  return (
    <div className="w-full flex flex-row items-center justify-center pt-6">
      <div
        style={{ maxWidth: 900 }}
        className="w-full flex md:flex-row flex-col px-12 py-2 justify-between items-center border-t"
      >
        <div className="flex flex-col" style={{ maxWidth: 300 }}>
          <Typography variant="body1">Time2You</Typography>
          <Typography variant="caption" className='hidden md:block'>
            Time2You - это облачное решение для быстрого и легкого онлайн
            бронирования
          </Typography>
        </div>
        <div className="flex flex-col text-center md:flex-row md:text-left">
          <a
            href="https://docs.google.com/document/d/1eTTc-kN4QdDsOiU8q9d83ms07gOoT-tIKeeIj5k5z3M/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography variant="caption">
              Пользовательское соглашение
            </Typography>
          </a>
          <a
            href="mailto:info@time2you.ru"
            className="md:before:content-['•'] md:before:mx-1"
          >
            <Typography variant="caption">info@time2you.ru</Typography>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
