import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { UserVM } from '../viewModels';
import AppService from '../services/appService';

export default function useUser(id: string | undefined) {
  return useQuery({
    queryKey: [queryKeys.users, id],
    enabled: !!id,
    queryFn: async () => {
      const service = new AppService();
      const user = await service.getUserById(id!);
      if (!user) {
        return null;
      }

      return {
        id: user.id,
        accountId: user.accountId,
        telegramId: user.telegramId,
        email: user.email,
        name: user.name,
        timezone: user.timezone,
      } as UserVM;
    },
    select: (data) => {
      return data;
    },
  });
}
