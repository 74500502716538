// src/firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyA-ZwYWd6Ijvh0yVhlTMCsXeBfu6bqXoEI',
  authDomain: 'time2you-8ba4a.firebaseapp.com',
  projectId: 'time2you-8ba4a',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const functions = getFunctions(app);
const db = getFirestore(app);

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  connectFunctionsEmulator(functions, '127.0.0.1', 5001);
  connectFirestoreEmulator(db, '127.0.0.1', 8080);
  connectAuthEmulator(auth, 'http://127.0.0.1:9099', {disableWarnings: true});
}

export { auth, functions, db };
