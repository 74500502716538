import { DateTimeSlotVM, TimeSlotVM } from '../viewModels';
import { DateTime } from 'luxon';

export function convertToTimeSlots(slots: DateTimeSlotVM[]): TimeSlotVM[] {
  return slots.map((slot) => ({
    start: slot.start.hour * 60 + slot.start.minute,
    end: slot.end.hour * 60 + slot.end.minute,
  }));
}

export function convertToDateTimeSlots(
  date: DateTime,
  slots: TimeSlotVM[],
): DateTimeSlotVM[] {
  return slots.map((slot) => {
    let startDate = DateTime.fromMillis(date.toMillis()).startOf('day');
    let endDate = DateTime.fromMillis(date.toMillis()).startOf('day');

    // Set start time
    startDate = startDate.set({
      minute: slot.start
    });

    // Set end time based on the slot range
    if (slot.end === 0) {
      // If end is 0, set to next day at 00:00
      endDate = endDate.set({
        day: endDate.day + 1,
        hour: 0,
        minute: 0,
      })
    } else {
      endDate = endDate.set({
        minute: slot.end,
      });
    }

    return {
      start: startDate,
      end: endDate,
    };
  });
}

export function toUtcDateTimeSlots(
  slots: DateTimeSlotVM[],
  timezone: string,
): DateTimeSlotVM[] {
  return slots.map((slot) => ({
    start: toUtcDate(slot.start, timezone),
    end: toUtcDate(slot.end, timezone),
  }));
}

export function toLocalDateTimeSlots(
  slots: DateTimeSlotVM[],
  timezone: string,
): DateTimeSlotVM[] {
  return slots.map((slot) => ({
    start: toLocalDate(slot.start, timezone),
    end: toLocalDate(slot.end, timezone),
  }));
}

export function toUtcDateTimeSlot(
  slot: DateTimeSlotVM,
  timezone: string,
): DateTimeSlotVM {
  return {
    start: toUtcDate(slot.start, timezone),
    end: toUtcDate(slot.end, timezone),
  };
}

export function toLocalDateTimeSlot(
  slot: DateTimeSlotVM,
  timezone: string,
): DateTimeSlotVM {
  return {
    start: toLocalDate(slot.start, timezone),
    end: toLocalDate(slot.end, timezone),
  };
}

export function toLocalDate(date: DateTime, timezone: string): DateTime {
  return date.setZone(timezone);
}

export function toUtcDate(date: DateTime, timezone: string): DateTime {
  return date.setZone('UTC');
}
