import React from 'react';

import { Box, Typography } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import useCreateAccount from '../../queryHelpers/useCreateAccount';
import toast from 'react-hot-toast';
import { useUserContext } from '../../contexts/UserContext';

interface Props {
  // Define your props here
}

const LoginAccountPage: React.FC<Props> = (props) => {
  const { user, actualizeStatus, logout } = useUserContext();
  const createAccountMutation = useCreateAccount();

  const handleClickConfirm = () => {
    if (!user) return;
    createAccountMutation.mutate(
      {
        userId: user!.id!,
      },
      {
        onSuccess: async (data) => {
          await actualizeStatus();
        },
        onError: (error) => {
          toast.error('Ошибка при создании аккаунта');
        },
      },
    );
  };

  const handleClickLogout = async () => {
    await logout();
  };

  return (
    <>
      {user && user.accountId && <Navigate to={'/admin'} />}
      {!user && <Navigate to={'/login'} />}
      <Box
        sx={{ height: '100vh' }}
        className="flex justify-center items-center"
      >
        <Box sx={{ mt: '-150px' }} className="flex flex-col space-y-2">
          <Box
            component={'img'}
            src="/images/logo.svg"
            style={{ height: '150px' }}
            alt="Logo"
            className="pb-4"
          />

          <div className="flex flex-col pb-4 items-center">
            <Typography variant="h4">
              У Вас еще нет аккаунта в системе
            </Typography>
          </div>

          <div className="flex flex-col space-y-2 items-center w-full">
            <LoadingButton
              onClick={handleClickConfirm}
              variant="contained"
              loading={createAccountMutation.isPending}
            >
              Создать и начать
            </LoadingButton>
            <LoadingButton onClick={handleClickLogout} variant="outlined">
              Выйти
            </LoadingButton>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default LoginAccountPage;
