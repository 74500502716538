import { ListItem, ListItemText, Paper, Typography } from '@mui/material';

type ItemProps = {
  title: string | JSX.Element;
  description?: string;
};

const Item: React.FC<ItemProps> = (props) => {
  return (
    <ListItem>
      <ListItemText primary={props.title}></ListItemText>
    </ListItem>
  );
};

const PriceSection = () => {
  const items: Omit<ItemProps, 'imagePosition'>[] = [
    {
      title: (
        <>
          5 рублей за <span className="font-bold">одну онлайн-запись</span>
        </>
      ),
    },
    {
      title: (
        <>
          20 онлайн-записей <span className="font-bold">бесплатно</span>
        </>
      ),
    },
    {
      title: (
        <>
          <span className="font-bold">Остальные функции</span> бесплатны
        </>
      ),
    },
  ];

  return (
    <div className="w-full flex flex-col space-y-2 justify-center items-center text-center">
      <Typography variant="h4">Стоимость</Typography>
      <Paper className="flex flex-row flex-wrap gap-6 border border-spacing-1">
        <div className="divide-y divide-gray-50  px-4">
          {items.map((item, index) => (
            <Item
              key={index}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
      </Paper>
    </div>
  );
};

export default PriceSection;
