import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Box } from '@mui/material';
import TimeSlotsEditor from './TimeSlotsEditor';
import { Label } from '../../styled/Label';
import { TimeSlotVM, DateTimeSlotVM } from '../../viewModels';
import { WeekDayAvailabilityFormInputs2 } from './DateAvailabilityViewEdit';
import { DayCalendarSelector } from './DayCalendarSelector';
import {
  convertToDateTimeSlots,
  convertToTimeSlots,
} from '../../helpers/dateHelpers';
import { DateTime } from 'luxon';

interface DateAvailabilityEditorProps {
  form: UseFormReturn<WeekDayAvailabilityFormInputs2>;
  value?: DateTimeSlotVM[];
  editMode: boolean;
  selectedDate?: DateTime;
  onSelectedDateChange: (date: DateTime | undefined) => void;
}

const DateAvailabilityEditor: React.FC<DateAvailabilityEditorProps> = (
  props,
) => {
  const availabilities = props.form?.watch('availabilities');

  const [dayAvailabilities, setDayAvailabilities] = useState<DateTimeSlotVM[]>(
    () =>
      availabilities?.filter((d) =>
        d.start.hasSame(props.selectedDate!, 'day'),
      ) ?? [],
  );

  const handleDateChange = (date: DateTime | undefined) => {
    var dayAvailabilities =
      availabilities?.filter((d) => d.start.hasSame(date!, 'day')) ?? [];
    setDayAvailabilities(dayAvailabilities);
    props.onSelectedDateChange?.(date);
  };

  const handleSlotsChange = (slots: TimeSlotVM[]) => {
    const newDateTimeSlots: DateTimeSlotVM[] = convertToDateTimeSlots(
      props.selectedDate!,
      slots,
    );

    const availabilitiesWithoutSelectedDate =
      availabilities?.filter(
        (d) => !d.start.hasSame(props.selectedDate!, 'day'),
      ) ?? [];

    props.form?.setValue('availabilities', [
      ...availabilitiesWithoutSelectedDate,
      ...newDateTimeSlots,
    ]);
    setDayAvailabilities(newDateTimeSlots);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'stretch',
      }}
    >
      <Box
        sx={{
          flex: 'auto',
          ml: -3,
        }}
      >
        <DayCalendarSelector
          onChange={handleDateChange}
          value={props.selectedDate}
          availabilities={availabilities}
        />
      </Box>
      <Box sx={{ flex: 'auto' }}>
        <Label>Время</Label>
        <TimeSlotsEditor
          slots={convertToTimeSlots(dayAvailabilities)}
          onChange={props.editMode ? handleSlotsChange : undefined}
        />
      </Box>
    </Box>
  );
};

export default DateAvailabilityEditor;
