import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Fab,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { Outlet, useOutletContext, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
// Import icons for the bottom navigation
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';
import { routes } from '../../routes';
import { WorkOutline } from '@mui/icons-material';

export type TopAppBarActionItem = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

export type TopAppBarProps = {
  title: string;
  backButtonPath?: string;
  actionItems?: TopAppBarActionItem[];
  fab?: BottomAppBarFab;
};

export type BottomAppBarFab = {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
};

export type BottomAppBarProps = {
  fab?: BottomAppBarFab;
};

export type AppBarsProps = {
  TopAppBar?: TopAppBarProps;
  BottomAppBar?: BottomAppBarProps;
};

export type AdminOutletContext = {
  setAppBars: (props: AppBarsProps) => void;
};

export function useAdminOutletContext() {
  return useOutletContext<AdminOutletContext>();
}

export default function AdminLayout() {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const { offsetWidth, offsetHeight } = containerRef.current;
      console.log(`Container size: ${offsetWidth} x ${offsetHeight}`);
    }
  }, []);

  const [appBarsProps, setAppBarProps] = useState<AppBarsProps | null>(null);

  const setAppBars = (props: AppBarsProps) => {
    setAppBarProps(props);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate(routes.admin.bookings);
        break;
      case 1:
        navigate(routes.admin.experiences);
        break;
      case 2:
        navigate(routes.admin.settings);
        break;
    }
  };

  useEffect(() => {
    // Update the selected value based on the current path
    const path = location.pathname;
    if (path.includes(routes.admin.bookings)) setValue(0);
    else if (path.includes(routes.admin.experiences)) setValue(1);
    else if (path.includes(routes.admin.settings)) setValue(2);
  }, [location]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <AppBar component="nav" variant="elevation" elevation={0} color="transparent">
        <Toolbar sx={{ position: 'relative' }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: 'bold',
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            {appBarsProps?.TopAppBar?.title || 'Contacts'}
          </Typography>
          <Box sx={{ marginLeft: 'auto' }}>
            {appBarsProps?.TopAppBar?.fab ? (
              <IconButton
                color="primary"
                aria-label={appBarsProps.TopAppBar.fab.title}
                onClick={appBarsProps.TopAppBar.fab.onClick}
              >
                {appBarsProps.TopAppBar.fab.icon}
              </IconButton>
            ) : null}
          </Box>
        </Toolbar>
      </AppBar>

      <Box sx={{ flexGrow: 1, overflow: 'auto', mt: { xs: 8, sm: 10 }, mb: 8 }}>
        <Box component="main">
          <Outlet
            context={
              {
                setAppBars,
              } satisfies AdminOutletContext
            }
          />
        </Box>
      </Box>

      <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
        <BottomNavigation value={value} onChange={handleChange} showLabels>
          <BottomNavigationAction label="Записи" icon={<CalendarMonthIcon />} />
          <BottomNavigationAction label="Услуги" icon={<WorkOutline />} />
          <BottomNavigationAction label="Настройки" icon={<SettingsIcon />} />
        </BottomNavigation>
      </AppBar>

      {appBarsProps?.BottomAppBar?.fab && (
        <BottomNavigation
          style={{ backgroundColor: 'transparent' }}
          sx={{
            position: 'fixed',
            bottom: 0,
            width: 1,
          }}
        >
          <Box sx={{ position: 'absolute', bottom: 20 }}>
            <Fab
              color="primary"
              onClick={appBarsProps?.BottomAppBar.fab.onClick}
              title={appBarsProps?.BottomAppBar.fab.title}
            >
              {appBarsProps?.BottomAppBar.fab.icon}
            </Fab>
          </Box>
        </BottomNavigation>
      )}

      {/* <BottomNav /> */}
    </Box>
  );
}
