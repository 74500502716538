import { Apartment, Videocam, Work } from '@mui/icons-material';
import { Card, CardContent, Typography } from '@mui/material';

type ItemProps = {
  title: string;
  description: string;
  icon: React.ReactNode;
};

const Item: React.FC<ItemProps> = (props) => {
  return (
    <Card sx={{ width: 300 }} elevation={0}>
      <CardContent className="flex flex-col items-center justify-center text-center">
        <div
          className="text-white rounded-full bg-primary mb-4"
          style={{ fontSize: 30, width: 50, height: 50 }}
        >
          {props.icon}
        </div>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          className="text-center"
          color="primary"
        >
          {props.title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          className="text-center"
        >
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

const UseCasesSection = () => {
  const items: ItemProps[] = [
    {
      title: 'Запись на приемы и услуги',
      description:
        'Если вы оказываете различного рода оффлайн услуги, как например, консультации, мастер-классы, тренинги, медицинские услуги и т.д.',
      icon: <Work />,
    },
    {
      title: 'Бронирование помещений или оборудования',
      description:
        'Если вы сдаете в аренду помещения или оборудование, как например, залы, кабинеты, аппараты и т.д.',
      icon: <Apartment />,
    },
    {
      title: 'Запись на онлайн встречи',
      description:
        'Если вы оказываете услуги удаленно, как например, консультации, тренинги, медицинские услуги и т.д.',
      icon: <Videocam />,
    },
  ];

  return (
    <div className="w-full flex flex-col space-y-6 justify-center items-center text-center">
      <Typography variant="h4">3 способа использования Time2You</Typography>
      <div className="flex flex-row flex-wrap justify-center gap-0 md:gap-6">
        {items.map((item) => (
          <Item
            key={item.title}
            title={item.title}
            description={item.description}
            icon={item.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default UseCasesSection;
