import React, { useEffect } from 'react';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ViewEditControl from '../viewEditControl/ViewEditControl';
import UserSettingsEditor from './SettingsUserEditor';
import useUser from '../../queryHelpers/useUser';
import * as yup from 'yup';
import useUpdateUserSettings from '../../queryHelpers/useUpdateUserSettings';
import { useEditForm } from '../../hooks/useEditForm';
import { getTimezoneDisplayText } from '../TimezoneSelector';
import { UserVM } from '../../viewModels';
import { useTranslation } from 'react-i18next';

type UserSettingsViewEditProps = {
  userId: string;
};

const SettingsUserFormSchema = yup
  .object({
    name: yup.string().required('Введите имя'),
    email: yup
      .string()
      .email('Введите валидный email адрес')
      .required('Введите email адрес'),
    timezone: yup.string().required('Выберите часовой пояс'),
  })
  .required();

export type SettingsUserFormInputs = Pick<
  UserVM,
  'name' | 'email' | 'timezone'
>;

const SettingsUserViewEdit: React.FC<UserSettingsViewEditProps> = (props) => {
  const userQuery = useUser(props.userId);
  const { t } = useTranslation();
  const { form, handleCancel } = useEditForm<SettingsUserFormInputs>({
    defaultValues: userQuery.data,
    formSchema: SettingsUserFormSchema,
  });

  useEffect(() => {
    if (userQuery.data) {
      form.reset(userQuery.data);
    }
  }, [form, userQuery.data]);

  const updateUserSettingsMutation = useUpdateUserSettings();

  const onSubmit = (data: SettingsUserFormInputs) => {
    updateUserSettingsMutation.mutate({
      ...userQuery.data!,
      ...data,
      id: props.userId,
    });
  };

  return (
    <ViewEditControl
      title="Личная информация"
      editMode="inline"
      viewFields={[
        {
          icon: <PersonIcon />,
          label: 'Имя',
          value: userQuery.data?.name || '',
        },
        {
          icon: <EmailIcon />,
          label: 'Email',
          value: userQuery.data?.email || '',
        },
        {
          icon: <AccessTimeIcon />,
          label: 'Часовой пояс',
          value: (() => {
            const timezone = getTimezoneDisplayText(userQuery.data?.timezone || '');
            return timezone ? `(${timezone.offset}) ${t(timezone.name)}` : '';
          })(),
        },
      ]}
      editor={<UserSettingsEditor form={form} />}
      editorIsValid={form.formState.isValid}
      onCancel={handleCancel}
      onSave={form.handleSubmit(onSubmit)}
    />
  );
};

export default SettingsUserViewEdit;
