import React from 'react';
import ViewEditControl from './viewEditControl/ViewEditControl';
import useUser from '../queryHelpers/useUser';
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTimeOutlined';
import AddBalanceIcon from '@mui/icons-material/AddCardOutlined';
import { pluralCredits } from '../helpers/pluralHelpers';
import useAccount from '../queryHelpers/useAccount';
import config from '../config';

type SubscriptionViewEditProps = {
  userId: string;
};

const SettingsSubscriptionViewEdit: React.FC<SubscriptionViewEditProps> = (
  props,
) => {
  const userQuery = useUser(props.userId);
  const accountQuery = useAccount(userQuery.data?.accountId);
  const account = accountQuery.data;

  return (
    <ViewEditControl
      title="Подписка"
      editMode="inline"
      viewEditIcon={<AddBalanceIcon />}
      viewFields={[
        {
          icon: <AccessTimeIcon />,
          label: 'Следующее списание',
          value:
            account?.paidAccessUntil &&
            pluralCredits(config.subscriptionCost) +
              ', ' +
              account?.paidAccessUntil,
        },
        {
          icon: <AccountBalanceIcon />,
          label: 'Баланс',
          value: pluralCredits(account?.balance || 0),
        },
      ]}
      editor={null}
      onCancel={() => {}}
      onSave={async () => {}}
    />
  );
};

export default SettingsSubscriptionViewEdit;
