import {
  Box,
  Avatar,
  Skeleton,
  Card,
  CardHeader,
  CardActions,
  Button,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { CreateExperienceDialog } from './components/CreateExperienceDialog';
import { useEffect, useState } from 'react';
import useExperiences from '../../../queryHelpers/useExperiences';
import { routes } from '../../../routes';
import { useAdminOutletContext } from '../AdminLayout';
import { ExperienceVM } from '../../../viewModels';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LinkIcon from '@mui/icons-material/Link';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import toast from 'react-hot-toast';


export default function ExperiencesPage() {
  const navigate = useNavigate();
  const [openCreateExperience, setOpenCreateExperience] = useState(false);
  const outletContext = useAdminOutletContext();

  useEffect(() => {
    outletContext.setAppBars({
      TopAppBar: {
        title: 'Услуги',
        fab: {
          title: 'Создать',
          icon: <AddIcon />,
          onClick: () => setOpenCreateExperience(true),
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isFetched } = useExperiences();
  if (!isFetched) {
    return (
      <>
        <Skeleton variant="circular" width={50} height={50}>
          <Avatar />
        </Skeleton>
        <Skeleton />
      </>
    );
  }

  const handleCopyLink = (link: string) => {
    navigator.clipboard.writeText(link);
    toast.success('Ссылка скопирована');
  };

  const handleShare = (experience: ExperienceVM) => {
    // Реализация функции поделиться
    console.log('Делимся:', experience.summary.title);
    toast.success('Функция "Поделиться" пока не реализована');
  };

  const handleEditClick = (experience: ExperienceVM) => {
    navigate(routes.admin.experienceDetail(experience.id!));
  };

  return (
    <>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
            gap: 2,
            p: 2,
          }}
        >
          {data!.map((experience: ExperienceVM, index) => (
            <Card key={index}>
              <CardHeader
                avatar={
                  <Avatar
                    sx={{
                      width: 50,
                      height: 50,
                      fontSize: 20,
                      bgcolor: 'primary.main',
                    }}
                  >
                    {experience.summary.title[0].toUpperCase()}
                  </Avatar>
                }
                title={experience.summary.title}
                titleTypographyProps={{ variant: 'h6' }}
              />
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <AccessTimeIcon sx={{ mr: 1, fontSize: 'small', color: 'text.secondary' }} />
                  <Typography variant="body2" color="text.secondary">
                    {experience.summary.duration} мин
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <LinkIcon sx={{ mr: 1, fontSize: 'small', color: 'text.secondary' }} />
                  <Typography
                    variant="body2"
                    component="a"
                    href={experience.summary.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: 'primary.main', textDecoration: 'none' }}
                  >
                    Страница бронирования
                  </Typography>
                </Box>
              </CardContent>
              <CardActions sx={{ justifyContent: 'space-between' }}>
                <Box>
                  <Tooltip title="Копировать ссылку">
                    <IconButton onClick={() => handleCopyLink(experience.summary.link)}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Поделиться">
                    <IconButton onClick={() => handleShare(experience)}>
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Button
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={() => handleEditClick(experience)}
                >
                  Изменить
                </Button>
              </CardActions>
            </Card>
          ))}
        </Box>

        <CreateExperienceDialog
          open={openCreateExperience}
          onClose={() => setOpenCreateExperience(false)}
        />
    </>
  );
}
