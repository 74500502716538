import React from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ViewField } from './ViewEditControl';

interface ViewDetailsSectionProps {
  fields: ViewField[];
}

const ViewDetailsSection: React.FC<ViewDetailsSectionProps> = (props) => {
  return (
    <>
      <List sx={{ width: 1, px: 0 }}>
        {props.fields.map((field, index) => (
          <ListItem
            key={index}
            sx={{
              px: 0,
              '&.MuiListItem-root': {
                paddingTop: 0.2,
                paddingBottom: 0.2,
              },
              cursor: field.onClick ? 'pointer' : 'default',
            }}
            onClick={field.onClick}
          >
            <ListItemIcon>{field.icon}</ListItemIcon>

            <ListItemText
              primary={field.value || 'Нет'}
              secondary={field.label}
              primaryTypographyProps={{
                style: {
                  // fontWeight: field.value ? 'normal' : 'lighter', // Bold if no value
                  color: field.value ? 'inherit' : 'gray', // Red text if no value
                  // fontStyle: field.value ? 'normal' : 'italic', // Red text if no value
                },
              }}
            />
            {field.button && (
              <IconButton onClick={field.button.onClick}>
                {field.button.icon}
              </IconButton>
            )}
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default ViewDetailsSection;
