import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json'; // Import English translations
import ru from './translations/ru.json'; // Import Russian translations

i18n.use(initReactI18next).init({
  resources: {
    ru: {
      translation: {
        ...ru, // Include Russian translations
      },
    },
    en: {
      translation: {
        ...en, // Include English translations
      },
    },
  },
  lng: 'ru', // Set the default language here
  fallbackLng: 'ru', // Fallback language if the current language is not available
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

export default i18n;
