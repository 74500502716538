import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { SetGoogleIntegration } from '../firebaseFunctions';

interface UseSetGoogleIntegrationVariables {
  accountId: string;
  code: string;
}

export default function useSetGoogleIntegration() {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    unknown,
    Error,
    UseSetGoogleIntegrationVariables,
    unknown
  >({
    mutationFn: async (data: UseSetGoogleIntegrationVariables) => {
      await SetGoogleIntegration({
        code: data.code,
      });
    },

    onSuccess: (_, variables, __) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.accounts, variables.accountId],
      });
    },
  });

  return mutation;
}
