import { Done } from '@mui/icons-material';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

type ItemProps = {
  title: string;
  description: string;
};

const Item: React.FC<ItemProps> = (props) => {
  return (
    <ListItem>
      <ListItemIcon sx={{ fontSize: 25 }}>
        <Done color="primary" />
      </ListItemIcon>
      <ListItemText
        primary={props.title}
        secondary={props.description}
      ></ListItemText>
    </ListItem>
  );
};

const FeaturesSection = () => {
  const items: Omit<ItemProps, 'imagePosition'>[] = [
    {
      title: 'Онлай-запись',
      description: 'Заведите все Ваши услуги для онлайн-записи',
    },
    {
      title: 'Персональная ссылка',
      description: 'Выберите красивую ссылку для онлайн-записи и делитесь ей',
    },
    {
      title: 'Онлайн оповещение',
      description:
        'Мы оповестим Вас и клиента о записи и ее изменении через Telegram или VK',
    },
    {
      title: 'Индивидуальное расписание',
      description: 'Укажите расписание для каждой услуги',
    },
    {
      title: 'Особые условия для записи',
      description:
        'Количество записей на день, перерыв между записями, исключение окон',
    },
    {
      title: 'Синхронизация с календарем',
      description: 'Смотрите онлайн-записи в личном календаре и управляйте ей.',
    },
  ];

  return (
    <div className="w-full flex flex-col space-y-6 justify-center items-center text-center">
      <Typography variant="h4">Функции Time2You</Typography>
      <div>
        {items.map((item, index) => (
          <Item key={index} title={item.title} description={item.description} />
        ))}
      </div>
    </div>
  );
};

export default FeaturesSection;
