import TelegramLoginButton from '../telegramLoginButton/TelegramLoginButton';
import { Avatar, Chip } from '@mui/material';
import { useUserContext } from '../../contexts/UserContext';

export default function UserLoginStatus() {
  const { user, logout } = useUserContext();

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      {user ? (
        <>
          <Chip
            avatar={
              <Avatar alt={user.name} src="/static/images/avatar/1.jpg" />
            }
            label={user.name}
            variant="outlined"
            onDelete={handleLogout}
          />
        </>
      ) : (
        <></>
      )}
      <TelegramLoginButton />
    </>
  );
}
