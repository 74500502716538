import { httpsCallable } from 'firebase/functions';
import { functions } from './firebaseConfig';
import {
  AuthTelegramRequest,
  AuthVkRequest,
  CreateAccountRequest,
  CreateExperienceRequest,
  CreateExperienceResponse,
  UpdateExperienceRequest,
  DeleteExperienceRequest,
  CreateBookingRequest,
  CreateBookingResponse,
  UpdateBookingRequest,
  UpdateUserRequest,
  DeleteBookingRequest,
  GetAvailableTimeSlotsRequest,
  GetAvailableTimeSlotsResponse,
  SetGoogleIntegrationRequest,
  SetGoogleIntegrationResponse,
} from 'shared-ts/functions.dto';

export const TelegramAuth = httpsCallable<AuthTelegramRequest>(
  functions,
  'AuthTelegram',
);
export const VkAuth = httpsCallable<AuthVkRequest>(functions, 'AuthVk');
export const CreateAccount = httpsCallable<CreateAccountRequest>(
  functions,
  'CreateAccount',
);

// Adding the missing functions
export const CreateExperienceFunc = httpsCallable<
  CreateExperienceRequest,
  CreateExperienceResponse
>(functions, 'CreateExperience');
export const UpdateExperienceFunc = httpsCallable<UpdateExperienceRequest>(
  functions,
  'UpdateExperience',
);
export const DeleteExperience = httpsCallable<DeleteExperienceRequest>(
  functions,
  'DeleteExperience',
);

export const UpdateUserFunc = httpsCallable<UpdateUserRequest>(
  functions,
  'UpdateUser',
);

export const CreateBooking = httpsCallable<
  CreateBookingRequest,
  CreateBookingResponse
>(functions, 'CreateBooking');

export const UpdateBooking = httpsCallable<UpdateBookingRequest>(
  functions,
  'UpdateBooking',
);

export const DeleteBooking = httpsCallable<DeleteBookingRequest>(
  functions,
  'DeleteBooking',
);

export const GetAvailableTimeSlots = httpsCallable<
  GetAvailableTimeSlotsRequest,
  GetAvailableTimeSlotsResponse
>(functions, 'GetAvailableTimeSlots');

export const SetGoogleIntegration = httpsCallable<
  SetGoogleIntegrationRequest,
  SetGoogleIntegrationResponse
>(functions, 'SetGoogleIntegration');

export const AddGoogleCalendarConnection = httpsCallable(
  functions,
  'AddGoogleCalendarConnection',
);
