import React from 'react';
import EventIcon from '@mui/icons-material/EventOutlined';
import ViewEditControl from '../viewEditControl/ViewEditControl';
import * as yup from 'yup';
import { useEditForm } from '../../hooks/useEditForm';
import useBooking from '../../queryHelpers/useBooking';
import BookingScheduleEditor from './BookingScheduleEditor';
import useUpsertBooking from '../../queryHelpers/useUpsertBooking';
import { BookingScheduleVM, DateTimeSlotVM } from '../../viewModels';
import { Nullable } from '../../types';
import { useTimeZone } from '../../contexts/TimeZoneContext';

type BookingScheduleViewEditProps = {
  bookingId: string;
};

const BookingScheduleFormSchema = yup
  .object({
    timeSlot: yup.object<DateTimeSlotVM>().required('Выберите время'),
  })
  .required();

export type BookingScheduleFormInputs = Nullable<BookingScheduleVM>;

const BookingScheduleViewEdit: React.FC<BookingScheduleViewEditProps> = (
  props,
) => {
  const { timezone } = useTimeZone();
  const bookingQuery = useBooking(props.bookingId);
  const appForm = useEditForm<BookingScheduleFormInputs>({
    defaultValues: {
      timeSlot: bookingQuery.data?.timeSlot,
    },
    formSchema: BookingScheduleFormSchema,
  });

  const upsertBookingMutation = useUpsertBooking();

  const onSubmit = async (data: BookingScheduleFormInputs) => {
    const dataWithUndefined = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        value === null ? undefined : value,
      ]),
    );

    upsertBookingMutation.mutate({
      ...bookingQuery.data!,
      ...dataWithUndefined,
      id: props.bookingId,
    });
  };

  return (
    <>
      <ViewEditControl
        title="Дата и время"
        viewFields={[
          {
            icon: <EventIcon />,
            label: 'Дата и время',
            value:
              bookingQuery.data?.timeSlot.start.setZone(timezone).toFormat('yyyy-MM-dd') +
              ', ' +
              bookingQuery.data!.timeSlot.start.setZone(timezone).toFormat('HH:mm') +
              ' - ' +
              bookingQuery.data!.timeSlot.end.setZone(timezone).toFormat('HH:mm'),
          },
        ]}
        editor={
          <BookingScheduleEditor
            experienceId={bookingQuery.data?.experienceId!}
            booking={bookingQuery.data!}
            form={appForm.form}
          />
        }
        editMode="inline"
        editorIsValid={appForm.form.formState.isValid}
        onCancel={appForm.handleCancel}
        onSave={appForm.form.handleSubmit(onSubmit)}
      />
    </>
  );
};

export default BookingScheduleViewEdit;

