import React from 'react';
import { TextField, Grid, FormHelperText } from '@mui/material';
import {
  Controller,
  UseFormReturn,
} from 'react-hook-form';
import { Label } from '../../styled/Label';
import { BookingClientFormInputs } from './BookingClientViewEdit';
import PhoneInput from '../PhoneInput';

interface BookingClientEditorProps {
  form: UseFormReturn<BookingClientFormInputs>;
}

const BookingClientEditor: React.FC<BookingClientEditorProps> = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Label>Имя</Label>
        <TextField
          size="small"
          error={!!props.form.formState.errors.name}
          {...props.form.register('name')}
          fullWidth
        />
        <FormHelperText error>{props.form.formState.errors.name?.message}</FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <Label>Телефон</Label>
        <Controller
          name="phone"
          control={props.form.control}
          render={({ field }) => (
            <PhoneInput
              value={field.value}
              onChange={(value) => field.onChange(value)}
              error={!!props.form.formState.errors.phone}
            />
          )}
        ></Controller>
        <FormHelperText error>{props.form.formState.errors.phone?.message}</FormHelperText>
      </Grid>
    </Grid>
  );
};

export default BookingClientEditor;
