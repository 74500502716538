import { forwardRef } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';

interface Props {}

const BottomNav = forwardRef<HTMLDivElement, Props>(
  (props, ref: React.ForwardedRef<HTMLDivElement>) => {
    return (
      <BottomNavigation
        ref={ref}
        sx={{
          width: 1,
          position: 'fixed',
          bottom: 0,
          height: 65,
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: (t) => t.palette.divider,
        }}
      >
        <BottomNavigationAction
          label="Назад"
          icon={<ArrowBackIcon />}
          onClick={() => window.history.back()}
        />
      </BottomNavigation>
    );
  },
);

export default BottomNav;
