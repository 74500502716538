import { useEffect, useId } from 'react';
import config from '../../config';
import { CircularProgress } from '@mui/material';
import { TelegramUser } from 'shared-ts/telegram.types';
import { useUserContext } from '../../contexts/UserContext';

export default function TelegramLoginButton() {
  const { user, authPending, login } = useUserContext();
  const id = useId();

  useEffect(() => {
    const element = document.getElementById(`TelegramLogin_${id}`);
    if (user) {
      if (element) {
        element.innerHTML = '';
      }
    } else {
      const loginBtn = document.createElement('script');
      loginBtn.async = true;
      loginBtn.src = 'https://telegram.org/js/telegram-widget.js?22';
      loginBtn.setAttribute('data-telegram-login', config.telegramBot);
      loginBtn.setAttribute('data-size', 'large');
      loginBtn.setAttribute('data-radius', '8');
      loginBtn.setAttribute('data-onauth', 'window.onTelegramAuth(user)');
      loginBtn.setAttribute('data-request-access', 'write');
      element?.appendChild(loginBtn);

      window.onTelegramAuth = async (telegramUser: TelegramUser) => {
        await login({ tgUser: telegramUser });
      };
    }
  }, [user, login, id]);

  return (
    <>
      {authPending ? <CircularProgress /> : <></>}
      <div
        style={{ display: authPending ? 'none' : 'block' }}
        id={`TelegramLogin_${id}`}
      ></div>
    </>
  );
}
