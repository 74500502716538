import React from 'react';
import { TextField } from '@mui/material';
import ReactPhoneInput from 'react-phone-input-material-ui';

interface PhoneInputProps {
  value: string;
  error: boolean;
  helperText?: string;
  onChange: (value: string) => void;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  value,
  error,
  onChange,
  helperText,
}) => (
  <ReactPhoneInput
    component={TextField}
    label=""
    country={'ru'}
    value={value}
    countryCodeEditable={true}
    inputProps={{
      size: 'small',
      error: error,
      helperText: helperText,
    }}
    onChange={onChange}
  />
);

export default PhoneInput;
