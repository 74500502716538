import { useMemo } from 'react';
import { getTimeSlotsForSelection } from '../helpers/timeslotsHelpers';
import { BookingVM, DateTimeSlotVM, ExperienceVM } from '../viewModels';
import useAvailableTimeSlots from '../queryHelpers/useAvailableTimeSlots';
import { DateTime } from 'luxon';

const useTimeSlotsForSelection = (
  selectedDate: DateTime | null,
  experienceData?: ExperienceVM | null, // Adjust type as necessary
  booking?: BookingVM | null,
): DateTimeSlotVM[] => {
  const availableTimeSlotsQuery = useAvailableTimeSlots(
    experienceData?.id!,
    selectedDate ?? null,
    booking?.id,
  );

  return useMemo(() => {
    if (!availableTimeSlotsQuery.data || !experienceData) {
      return [];
    }

    const experienceDuration = experienceData.summary.duration!;

    const tmpTimeSlotsForSelection = getTimeSlotsForSelection(
      availableTimeSlotsQuery.data,
      experienceDuration,
    );

    if (booking) {
      const bookingDateTimeSlot = tmpTimeSlotsForSelection.find(
        (x) =>
          x.start.equals(booking.timeSlot.start!) &&
          x.end.equals(booking.timeSlot.end!),
      );

      if (!bookingDateTimeSlot) {
        tmpTimeSlotsForSelection.push(booking.timeSlot);
      }
    }

    return tmpTimeSlotsForSelection;
  }, [availableTimeSlotsQuery, booking, experienceData]);
};

export default useTimeSlotsForSelection;
