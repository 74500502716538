import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SummaryEditor from '../../../../components/experienceSummary/SummaryEditor';
import { useEditForm } from '../../../../hooks/useEditForm';
import {
  SummaryFormInputs,
  SummaryFormSchema,
} from '../../../../components/experienceSummary/SummaryViewEdit';
import useCreateExperience from '../../../../queryHelpers/useCreateExperience';
import { LoadingButton } from '@mui/lab';
import { routes } from '../../../../routes';
import { createNewExperienceVM } from '../../../../viewModels';

interface CreateExperienceDialogProps {
  open: boolean;
  onClose: () => void;
}

export const CreateExperienceDialog: React.FC<CreateExperienceDialogProps> = ({
  open,
  onClose,
}) => {
  const navigate = useNavigate();

  const {
    form: {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    },
  } = useEditForm<SummaryFormInputs>({
    defaultValues: {
      title: '',
      description: '',
      duration: 60,
      link: '',
    },
    formSchema: SummaryFormSchema(false),
  });

  const createExperienceMutation = useCreateExperience();

  const handleSave = (data: SummaryFormInputs) => {
    const newExperience = createNewExperienceVM();
    createExperienceMutation.mutate(
      {
        ...newExperience,
        summary: data,
      },
      {
        onSuccess: (data) => {
          onClose();
          navigate(routes.admin.experienceDetail(data.id));
        },
        onError: () => {},
      },
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', ml: -1 }}>
            <IconButton onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" sx={{ ml: 1 }}>
              Создать услугу
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <SummaryEditor
            autoLink={true}
            register={register}
            errors={errors}
            watch={watch}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={createExperienceMutation.isPending}
            fullWidth
            variant="contained"
            type="submit"
          >
            Создать
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
