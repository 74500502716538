import { PKSE } from '@vkid/sdk/dist-sdk/types/core/config/types';
import * as VKID from '@vkid/sdk';

export function initVkSdk(
  config: Pick<VKID.ConfigData, 'app' | 'redirectUrl'> &
    PKSE &
    Partial<VKID.ConfigData>,
) {
  VKID.Config.init(config);
}
