import { useEffect, useState } from 'react';
import * as VKID from '@vkid/sdk';

const handleError = () => {};

const VkLoginButton: React.FC = () => {
  const [oneTap, setOneTap] = useState<VKID.OneTap | null>(null);

  useEffect(() => {
    const container = document.getElementById('VkIdSdkOneTap');
    if (oneTap) {
      return;
    }

    const oneTapTmp = new VKID.OneTap();

    if (container) {
      oneTapTmp
        .render({
          container: container,
          scheme: VKID.Scheme.LIGHT,
          lang: VKID.Languages.RUS,
          styles: {
            width: 213,
            borderRadius: 8,
          },
        })
        .on(VKID.WidgetEvents.ERROR, handleError);
    }

    setOneTap(oneTap);

    return () => {
      oneTapTmp.close();
      setOneTap(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div id="VkIdSdkOneTap"></div>
    </>
  );
};

export default VkLoginButton;
