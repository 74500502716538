import { Typography, styled } from "@mui/material";

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  // fontWeight: 'bold',
  fontSize: 14,
  paddingBottom: 2,
  paddingLeft: 1,
  // textTransform: '',
  marginBottom: 0,
}));
