import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import AppService from '../services/appService';
import { AccountVM } from '../viewModels';

export default function useAccount(id: string | undefined) {
  return useQuery({
    queryKey: [queryKeys.accounts, id],
    queryFn: async () => {
      if (!id) {
        return null;
      }
      const service = new AppService();
      const account = await service.getAccountById(id);
      if (!account) {
        return null;
      }

      return {
        id: account.id,
        paidAccessUntil: account?.paidAccessUntil ?? null,
        balance: account?.balance ?? 0,
        nextPayment: account?.nextPayment ?? null,
        googleAccount: !account.googleAccount
          ? null
          : {
              accessToken: account.googleAccount.accessToken,
              expiryDate: account.googleAccount.expiryDate,
              idToken: account.googleAccount.idToken,
            },
      } as AccountVM;
    },
    select: (data) => {
      return data;
    },
  });
}
