import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Box } from '@mui/material';
import AppFullScreenDialog, {
  AppFullScreenDialogProps,
} from '../../../../components/AppFullScreenDialog';
import ExperienceSelector, {
  ExperienceSelectorFormInputs,
} from '../../../../components/ExperienceSelector';
import * as yup from 'yup';
import { BookingVM } from '../../../../viewModels';
import { useEditForm } from '../../../../hooks/useEditForm';
import BookingClientEditor from '../../../../components/bookingClient/BookingClientEditor';
import { BookingClientFormInputs } from '../../../../components/bookingClient/BookingClientViewEdit';
import BookingScheduleEditor from '../../../../components/bookingSchedule/BookingScheduleEditor';
import { BookingScheduleFormInputs } from '../../../../components/bookingSchedule/BookingScheduleViewEdit';
import FieldsSection from '../../../../components/FieldsSection';
import ViewDetailsSectionList from '../../../../styled/ViewDetailsSectionList';
import useExperience from '../../../../queryHelpers/useExperience';
import useUpsertBooking from '../../../../queryHelpers/useUpsertBooking';
import toast from 'react-hot-toast';

type CreateBookingDialogProps = {} & Pick<
  AppFullScreenDialogProps,
  'formParams' | 'isPending'
>;

const Schema = yup
  .object({
    experienceId: yup.string().required('Выберите услугу'),
    name: yup.string().required('Введите имя'),
    phone: yup
      .string()
      .min(11, 'Введите полный номер телефона')
      .required('Введите номер телефона'),
    // date: yup.string().required('Выберите дату'),
    timeSlot: yup.object().required('Выберите время'),
  })
  .required();

type CreateBookingFormInputs = Omit<
  BookingVM,
  'id' | 'experience' | 'createdIn'
>;

export const CreateBookingDialog: React.FC<CreateBookingDialogProps> = (
  props,
) => {
  const appForm = useEditForm<CreateBookingFormInputs>({
    formSchema: Schema,
    defaultValues: {
      experienceId: '',
      name: '',
      phone: '',
      date: null,
      timeSlot: null,
    },
  });
  const watchExperienceId = appForm.form.watch('experienceId');

  const experienceQuery = useExperience({
    id: watchExperienceId,
    enabled: !!watchExperienceId,
  });

  const upsertBookingMutation = useUpsertBooking();

  const handleSubmitted = async () => {
    await appForm.form.trigger();
    if (!appForm.form.formState.isValid) {
      return;
    }

    await upsertBookingMutation.mutateAsync(appForm.form.getValues(), {
      onSuccess: () => {
        toast.success('Запись успешно создана');
        props.formParams.onClose();
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
  };

  return (
    <AppFullScreenDialog
      formParams={{
        open: props.formParams.open,
        onClose: () => {
          appForm.handleCancel();
          props.formParams.onClose();
        },
      }}
      title="Новая запись"
      submitLabel="Создать"
      onSubmitted={handleSubmitted}
      isPendingSubmit={upsertBookingMutation.isPending}
      isPending={experienceQuery?.isLoading}
    >
      <Box className="p-4">
        <ViewDetailsSectionList>
          <FieldsSection title="Услуга" containerClassName="p-2">
            <ExperienceSelector
              form={
                appForm.form as unknown as UseFormReturn<ExperienceSelectorFormInputs>
              }
            />
          </FieldsSection>
          {watchExperienceId && (
            <>
              <FieldsSection title="Дата и время" containerClassName="p-2">
                <BookingScheduleEditor
                  form={
                    appForm.form as unknown as UseFormReturn<BookingScheduleFormInputs>
                  }
                  experienceId={watchExperienceId}
                />
              </FieldsSection>
              <FieldsSection title="Клиент" containerClassName="p-2">
                <BookingClientEditor
                  form={
                    appForm.form as unknown as UseFormReturn<BookingClientFormInputs>
                  }
                />
              </FieldsSection>
            </>
          )}
        </ViewDetailsSectionList>
      </Box>
    </AppFullScreenDialog>
  );
};
