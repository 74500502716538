import React from 'react';
import { TextField, Grid, Slider, FormHelperText } from '@mui/material';
import { pluralTime } from '../../helpers/pluralHelpers';
import { Label } from '../../styled/Label';
import { FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { SummaryFormInputs } from './SummaryViewEdit';

interface SummaryEditorProps {
  register: UseFormRegister<SummaryFormInputs>;
  errors: FieldErrors<SummaryFormInputs>;
  watch: UseFormWatch<SummaryFormInputs>;
  autoLink?: boolean;
}

const SummaryEditor: React.FC<SummaryEditorProps> = (props) => {
   return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Label>Название</Label>
        <TextField
          size="small"
          error={!!props.errors.title}
          {...props.register('title')}
          fullWidth
        />
        <FormHelperText error>{props.errors.title?.message}</FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <Label>Описание</Label>
        <TextField
          size="small"
          {...props.register('description')}
          multiline
          rows={3}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Label>Продолжительность {pluralTime(props.watch('duration')!)} </Label>
        <Slider {...props.register('duration')} step={5} min={5} max={720} />
      </Grid>
      {!props.autoLink && (
        <Grid item xs={12}>
          <Label>Ссылка</Label>
          <TextField
            size="small"
            error={!!props.errors.link}
            {...props.register('link', {
              required: { value: true, message: 'Укажите ссылку' },
            })}
            fullWidth
          />
          <FormHelperText>

            http://time2you.com/book/{props.watch('link')}
          </FormHelperText>
          <FormHelperText error>{props.errors.link?.message}</FormHelperText>
        </Grid>
      )}
    </Grid>
  );
};

export default SummaryEditor;
