import { Chair, Savings, Speed } from '@mui/icons-material';
import { Card, CardContent, Typography } from '@mui/material';

type BenefitsSectionItemProps = {
  title: string;
  description: string;
  icon: React.ReactNode;
};

const BenefitsSectionItem: React.FC<BenefitsSectionItemProps> = (props) => {
  return (
    <Card sx={{ width: 300 }} elevation={0}>
      <CardContent className="flex flex-col items-center justify-center text-center">
        <div className="text-primary align-top mb-10 -mt-12"
          style={{ fontSize: 100, height: 90 }}>
          {props.icon}
        </div>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          className="text-center"
          color="primary"
        >
          {props.title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          className="text-center"
        >
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

const BenefitsSection = () => {
  const items: BenefitsSectionItemProps[] = [
    {
      title: 'Удобно',
      description: 'Простой, интуитивный интерфейс',
      icon: <Chair />,
    },
    {
      title: 'Быстро',
      description: 'Запустить онлайн бронирование за 2 минуты',
      icon: <Speed />,
    },
    {
      title: 'Выгодно',
      description: 'Платите только за то, что используете',
      icon: <Savings />,
    },
  ];

  return (
    <div className="w-full flex flex-col space-y-6 justify-center items-center text-center">
      <Typography variant="h4">
        {items.length} причины пользоваться Time2You
      </Typography>
      <div className="flex flex-row flex-wrap justify-center gap-6 md:gap-6">
        {items.map((item) => (
          <BenefitsSectionItem
            key={item.title}
            title={item.title}
            description={item.description}
            icon={item.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default BenefitsSection;
