import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { BookingVM } from '../viewModels';
import { CreateBooking, UpdateBooking } from '../firebaseFunctions';
import { bookingVMToDb } from './mapping';
import { Booking } from 'shared-ts/entities';

export default function useUpsertBooking() {
  const queryClient = useQueryClient();

  const mutation = useMutation<string, Error, Partial<BookingVM>, unknown>({
    mutationFn: async (data: Partial<BookingVM>) => {
      const booking: Partial<Booking> = bookingVMToDb(data);

      if (booking.id) {
        // Update existing booking
        const updateBooking = { ...booking, id: booking.id };

        if (!updateBooking.start) {
          delete updateBooking.start;
        }
        if (!updateBooking.end) {
          delete updateBooking.end;
        }
        if (!updateBooking.userId) {
          delete updateBooking.userId;
        }
        if (!updateBooking.experienceId) {
          delete updateBooking.experienceId;
        }

        await UpdateBooking(updateBooking);
        return booking.id;
      } else {
        // Create new booking
        const result = await CreateBooking({
          ...booking,
          experienceId: booking.experienceId!,
          start: booking.start!,
          end: booking.end!,
          name: booking.name!,
          phone: booking.phone!,
          wishes: booking.wishes,
        });
        return result.data?.id;
      }
    },

    onSettled: (_, __, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.bookings],
      });

      if (variables.timeSlot) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.availableTimeSlots(variables.experienceId),
        });
      }
    },
  });

  return mutation;
}
