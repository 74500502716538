import React from 'react';
import { TextField, Grid, FormHelperText } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Label } from '../../styled/Label';
import { SettingsUserFormInputs } from './SettingsUserViewEdit';
import TimezoneSelector from '../TimezoneSelector';

interface UserSettingEditorProps {
  form: UseFormReturn<SettingsUserFormInputs>;
}

const SettingUserEditor: React.FC<UserSettingEditorProps> = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Label>Имя</Label>
        <TextField
          size="small"
          error={!!props.form.formState.errors.name}
          {...props.form.register('name')}
          fullWidth
        />
        <FormHelperText error>
          {props.form.formState.errors.name?.message}
        </FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <Label>Email</Label>
        <TextField
          size="small"
          error={!!props.form.formState.errors.email}
          {...props.form.register('email')}
          fullWidth
        />
        <FormHelperText error>
          {props.form.formState.errors.email?.message}
        </FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <Label>Часовой пояс</Label>
        <Controller
          name="timezone"
          control={props.form.control}
          render={({ field }) => (
            <TimezoneSelector
              value={field.value}
              onChange={(value: string) => field.onChange(value)}
              error={!!props.form.formState.errors.timezone}
            />
          )}
        />

        <FormHelperText error>
          {props.form.formState.errors.timezone?.message}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};

export default SettingUserEditor;
